import axios from 'axios';
import firebase from 'firebase';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import queryString from 'query-string';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import { defaultLanguage } from '../../prismic-configuration';
import { PersonalPortfolioCardForm } from '../components/Form-components/PersonalPortfolioCardForm/PersonalPortfolioCard';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { Spinner } from '../components/Spinner/Spinner';
import { linkResolver } from '../utils/LinkResolver';

const isBrowser = typeof window !== 'undefined';

const FinalStepApprovalProccess = ({ location, data }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState('pending');
  const [message, setMessage] = useState('');
  const [userUnderReview, setUserUnderReview] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [reviewingCollectionList, setReviewingCollectionList] = useState([]);
  const [applicantID, setApplicantID] = useState('');
  const [mcm1RevProcessID, setMcm1RevProcessID] = useState([]);
  const [mcm2RevProcessID, setMcm2RevProcessID] = useState([]);
  const [mcm3RevProcessID, setMcm3RevProcessID] = useState([]);
  const [finalAction, setFinalAction] = useState(['']);
  const [generalComments, setGeneralComments] = useState('');
  const [hasCompletedPdps, setHasCompletedPdps] = useState('');
  const [hasCompletedPdpsComments, setHasCompletedPdpsComments] = useState('');

  const [mcm1ReviewProcess, setMcm1ReviewProcess] = useState([]);
  const [mcm2ReviewProcess, setMcm2ReviewProcess] = useState([]);
  const [mcm3ReviewProcess, setMcm3ReviewProcess] = useState([]);
  const [formFieldEntriesMCM1, setFormFieldEntriesMCM1] = useState([]);
  const [formFieldEntriesMCM2, setFormFieldEntriesMCM2] = useState([]);
  const [formFieldEntriesMCM3, setFormFieldEntriesMCM3] = useState([]);
  const firstRender = useRef(true);

  //Get MCM Review processes Ids from params
  useEffect(() => {
    const params = queryString.parse(location.search);

    if (params.mcm1RevProcess || params.mcm2RevProcess || params.mcm3RevProcess) {
      setMcm1RevProcessID(params.mcm1RevProcess);
      setMcm2RevProcessID(params.mcm2RevProcess);
      setMcm3RevProcessID(params.mcm3RevProcess);
      setApplicantID(params.userToBeApprovedId);

      setIsDisabled(!!params.mcm1RevProcess && !!params.mcm2RevProcess && !!params.mcm3RevProcess);
    } else {
      setStatus('failed');
      setMessage('Missing needed information...');
    }
  }, [location.search]);

  //fetch if exist Firebase reviewingCollection data
  useLayoutEffect(() => {
    let isMounted = true;
    if (isBrowser && isMounted) {
      try {
        firebase
          //Accesses your Firestore database
          .firestore()
          //Access the "reviewingCollection" collection
          .collection('reviewingCollection')
          .onSnapshot(snapshot => {
            const reviewingCollection = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));

            // Now we set users equal to users we read from the Firestore

            setReviewingCollectionList(reviewingCollection);
          });
      } catch (error) {
        //prebuilt - 306f43d8 - 45d6f0b9.js: 13720 Uncaught Error in snapshot listener: FirebaseError: Missing or insufficient permissions.
      }
    }
    return () => {
      if (isBrowser && isMounted) {
        try {
          firebase
            .firestore()
            .collection('reviewingCollection')
            .onSnapshot(snapshot => {});
        } catch (error) {}
        isMounted = false;
      }
    };
  }, []);

  //get Applicant ID
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const mcm1RevProcess = reviewingCollectionList.filter(
        processFromList => processFromList.id === mcm1RevProcessID,
      )[0];
      // setmcm1RevProcess(mcm1RevProcess)
      const mcm2RevProcess = reviewingCollectionList.filter(
        processFromList => processFromList.id === mcm2RevProcessID,
      )[0];
      // setmcm2RevProcess(mcm2RevProcess)
      const mcm3RevProcess = reviewingCollectionList.filter(
        processFromList => processFromList.id === mcm3RevProcessID,
      )[0];
      // setmcm3RevProcess(mcm3RevProcess)
      setMcm1ReviewProcess(mcm1RevProcess);
      setMcm2ReviewProcess(mcm2RevProcess);
      setMcm3ReviewProcess(mcm3RevProcess);

      const entriesMCM1 = Object.entries(mcm1RevProcess ?? []);
      setFormFieldEntriesMCM1(entriesMCM1);
      const entriesMCM2 = Object.entries(mcm2RevProcess ?? []);
      setFormFieldEntriesMCM2(entriesMCM2);
      const entriesMCM3 = Object.entries(mcm3RevProcess ?? []);
      setFormFieldEntriesMCM3(entriesMCM3);
    }
  }, [reviewingCollectionList, mcm1RevProcessID, mcm2RevProcessID, mcm3RevProcessID]);
  //get Applicant data
  useEffect(() => {
    let isMounted = true;
    if (isBrowser && isMounted) {
      try {
        firebase
          .firestore()
          .collection('usersCollection')
          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            const userUnderReview = listUsers.filter(user => user.id === applicantID)[0];
            setUserUnderReview(userUnderReview);
            setGeneralComments(userUnderReview?.secretaryGeneralComments);
            setHasCompletedPdps(userUnderReview?.has_completed_pdps);
            setHasCompletedPdpsComments(userUnderReview?.has_completed_pdps_comments);
            setAllUsers(listUsers);
          });
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      if (isBrowser && isMounted) {
        try {
          firebase
            .firestore()
            .collection('usersCollection')
            .onSnapshot(snapshot => {
              const listUsers = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
              }));
            });
        } catch (error) {
          console.log(error);
        }
        isMounted = false;
      }
    };
  }, [applicantID]);

  let user = '';
  let firebaseAuth = {};
  let firestore = {};

  if (isBrowser) {
    user = firebase.auth().currentUser;
    firebaseAuth = firebase.auth();
    firestore = firebase.firestore();
  }

  if (!data) return null;

  const pageContent = data.prismicFinalStepApprovalProccess;
  const formLabelsFromPrismic = data.prismicProfessionalPortfolioCard?.data?.body[0]?.items;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;
  const topMenu = data.prismicMenuLink || {};
  const firstFormVars = data.prismicMcmReviewForm?.data?.body[0]?.items;
  const secondFormVars = data.prismicMcmReviewForm?.data?.body[1]?.items;

  const handleSendEmailOnDecline = async () => {
    handleUpdateStatus('Decline');
    setIsLoading(true);
    try {
      // Send data to the function,
      // await the result.
      await axios.post('/api/send-message-on-decline-applicant', {
        userName: userUnderReview?.full_name || 'no full_name',
        userRequestID: userUnderReview?.requestID || 'no requestID',
        userEmail: userUnderReview?.email || 'no email',
      });
      alert(
        'You have Declined to Register the Applicant. Email with all details have been sent to the Applicant',
      );
      navigate(`${localLinkResolver}/personal-page`);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };
  const handleOnApprove = async () => {
    handleUpdateStatus('Approve');
    setIsLoading(true);
    try {
      // Send data to the function,
      // await the result.
      // DISABLED BY REQUEST
      // await axios.post('/api/send-message-on-approve-applicant', {
      //   userName: userUnderReview?.full_name || 'no full_name',
      //   userRequestID: userUnderReview?.requestID || 'no requestID',
      //   userEmail: userUnderReview?.email || 'no email',
      // });
      alert('You have Approved the Application. Email with all details have been sent');
      navigate(`${localLinkResolver}/personal-page`);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const showDataFromReviewers = () => {
    if (isBrowser) {
      try {
        firebase.firestore().collection('usersCollection').doc(userUnderReview?.id).update({
          showDataFromReviewers: !userUnderReview?.showDataFromReviewers,
        });

        userUnderReview?.showDataFromReviewers
          ? alert('Review results is not visible for the user')
          : alert('Review results is now visible for the user');
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log('not in Browser');
    }
  };
  const handleUpdateStatus = label => {
    // setFinalAction([label])
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    const newStatus = label === 'Decline' ? 'Not registered' : 'Portfolio reviewed';
    try {
      firebase
        .firestore()
        .collection('usersCollection')
        .doc(userUnderReview?.id)
        .update({
          under_review: false,
          currentStatus: newStatus,
          secretaryGeneralComments: generalComments || 'No Comments', //comments from secretary related to the reviews from mcm
          has_completed_pdps: hasCompletedPdps || 'Please select',
          has_completed_pdps_comments: hasCompletedPdpsComments || ' ',
          showDataFromReviewers: false,
          mcm1ReviewProcess: mcm1ReviewProcess,
          mcm2ReviewProcess: mcm2ReviewProcess,
          mcm3ReviewProcess: mcm3ReviewProcess,
        });
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };

  const handleStepBack = () => {
    setFinalAction(['']);
  };
  // console.log(setIsDisabled)

  const ToggleButtonReviewsResults = () => {
    return (
      <p>
        Show Data From Reviewers
        <button
          className="btn btn-outline-success m-3"
          onClick={showDataFromReviewers}
          type="button"
        >
          {userUnderReview?.showDataFromReviewers ? 'Hide' : 'Show'}
        </button>
      </p>
    );
  };
  // console.log('=>>>>>', mcm1RevProcessID)
  return (
    <Layout topMenu={topMenu?.data} activeDocMeta={activeDoc}>
      <Seo title={page?.title?.text} lang={lang} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Container className="shadow mt-5">
          <br />
          {finalAction.includes('') && (
            <Container fluid className="personal-portfolio">
              <h1 className="page-title">{page.title.text}</h1>
              <h6>ENTER Register Applicant Name: {userUnderReview?.full_name}</h6>
              <br />
              <div className="final-step-approval-proccess border border-3 shadow">
                {/* formFieldEntriesMCM1 */}
                <div className="fullPersonalPortfolio p-4">
                  {firstFormVars?.map((field, key) => {
                    //name: "applicantID"
                    const fieldValueArray = formFieldEntriesMCM1.filter(
                      userField => userField[0] === field.name,
                    );

                    let fieldValue;

                    for (const [key, value] of Object.entries(fieldValueArray)) {
                      fieldValue = value[1];
                    }
                    if (field.field_type === 'Long Input') {
                      return (
                        <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                          {field.placeholder}{' '}
                          <span
                            className={`value long-input border  border-1  rounded-3 text-break ${
                              fieldValue ? 'border-info' : 'border-secondary'
                            }`}
                          >
                            {fieldValue ? fieldValue : 'Not provided'}
                          </span>
                        </li>
                      );
                    }
                    if (field.name === 'mcm_name') {
                      return (
                        <div key={key}>
                          <li className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                            Individual Review Proccess{' '}
                            <span
                              className={`value border  border-1  rounded-3 text-break ${
                                fieldValue ? 'border-info' : 'border-secondary'
                              }`}
                            >
                              {mcm1RevProcessID ? mcm1RevProcessID : 'Not provided'}
                            </span>
                          </li>
                          <li className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                            Reviewer name{' '}
                            <span
                              className={`value border  border-1  rounded-3 text-break ${
                                fieldValue ? 'border-info' : 'border-secondary'
                              }`}
                            >
                              {fieldValue ? fieldValue : 'Not provided'}
                            </span>
                          </li>
                        </div>
                      );
                    }
                    return (
                      <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                        {field.title}{' '}
                        <span
                          className={`value border  border-1  rounded-3 text-break ${
                            fieldValue ? 'border-info' : 'border-secondary'
                          }`}
                        >
                          {fieldValue ? fieldValue : 'Not provided'}
                        </span>
                      </li>
                    );
                  })}
                  {secondFormVars?.map((field, key) => {
                    //dpds disabled by client request
                    if (
                      field.name === 'has_completed_pdps' ||
                      field.name === 'has_completed_pdps_comments'
                    ) {
                      return;
                    }
                    const fieldValueArray = formFieldEntriesMCM1.filter(
                      userField => userField[0] === field.name,
                    );
                    let fieldValue;

                    for (const [key, value] of Object.entries(fieldValueArray)) {
                      fieldValue = value[1];
                    }
                    if (field.field_type === 'Long Input') {
                      return (
                        <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                          {field.placeholder}{' '}
                          <span
                            className={`value long-input  border  border-1  rounded-3 text-break ${
                              fieldValue ? 'border-info' : 'border-secondary'
                            }`}
                          >
                            {fieldValue ? fieldValue : 'Not provided'}
                          </span>
                        </li>
                      );
                    }
                    return (
                      <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                        {field.title}{' '}
                        <span
                          className={`value border  border-1  rounded-3 text-break ${
                            fieldValue ? 'border-info' : 'border-secondary'
                          }`}
                        >
                          {fieldValue ? fieldValue : 'Not provided'}
                        </span>
                      </li>
                    );
                  })}
                </div>
                {/* formFieldEntriesMCM2 */}
                <div className="fullPersonalPortfolio p-4">
                  {firstFormVars?.map((field, key) => {
                    const fieldValueArray = formFieldEntriesMCM2.filter(
                      userField => userField[0] === field.name,
                    );
                    let fieldValue;

                    for (const [key, value] of Object.entries(fieldValueArray)) {
                      fieldValue = value[1];
                    }
                    if (field.field_type === 'Long Input') {
                      return (
                        <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                          {field.placeholder}{' '}
                          <span
                            className={`value long-input border  border-1  rounded-3 text-break ${
                              fieldValue ? 'border-info' : 'border-secondary'
                            }`}
                          >
                            {fieldValue ? fieldValue : 'Not provided'}
                          </span>
                        </li>
                      );
                    }
                    if (field.name === 'mcm_name') {
                      return (
                        <div key={key}>
                          <li
                            key={key}
                            className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}
                          >
                            Individual Review Proccess{' '}
                            <span
                              className={`value border  border-1  rounded-3 text-break ${
                                fieldValue ? 'border-info' : 'border-secondary'
                              }`}
                            >
                              {mcm2RevProcessID ? mcm2RevProcessID : 'Not provided'}
                            </span>
                          </li>
                          <li
                            key={key + 1}
                            className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}
                          >
                            Reviewer name{' '}
                            <span
                              className={`value border  border-1  rounded-3 text-break ${
                                fieldValue ? 'border-info' : 'border-secondary'
                              }`}
                            >
                              {fieldValue ? fieldValue : 'Not provided'}
                            </span>
                          </li>
                        </div>
                      );
                    }
                    return (
                      <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                        {field.title}{' '}
                        <span
                          className={`value border  border-1  rounded-3 text-break ${
                            fieldValue ? 'border-info' : 'border-secondary'
                          }`}
                        >
                          {fieldValue ? fieldValue : 'Not provided'}
                        </span>
                      </li>
                    );
                  })}
                  {secondFormVars?.map((field, key) => {
                    //dpds disabled by client request
                    if (
                      field.name === 'has_completed_pdps' ||
                      field.name === 'has_completed_pdps_comments'
                    ) {
                      return;
                    }
                    const fieldValueArray = formFieldEntriesMCM2.filter(
                      userField => userField[0] === field.name,
                    );
                    let fieldValue;

                    for (const [key, value] of Object.entries(fieldValueArray)) {
                      fieldValue = value[1];
                    }
                    if (field.field_type === 'Long Input') {
                      return (
                        <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                          {field.placeholder}{' '}
                          <span
                            className={`value long-input border  border-1  rounded-3 text-break ${
                              fieldValue ? 'border-info' : 'border-secondary'
                            }`}
                          >
                            {fieldValue ? fieldValue : 'Not provided'}
                          </span>
                        </li>
                      );
                    }
                    return (
                      <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                        {field.title}{' '}
                        <span
                          className={`value border  border-1  rounded-3 text-break ${
                            fieldValue ? 'border-info' : 'border-secondary'
                          }`}
                        >
                          {fieldValue ? fieldValue : 'Not provided'}
                        </span>
                      </li>
                    );
                  })}
                </div>
                {/* formFieldEntriesMCM3 */}
                <div className="fullPersonalPortfolio p-4">
                  {firstFormVars?.map((field, key) => {
                    const fieldValueArray = formFieldEntriesMCM3.filter(
                      userField => userField[0] === field.name,
                    );
                    let fieldValue;

                    for (const [key, value] of Object.entries(fieldValueArray)) {
                      fieldValue = value[1];
                    }
                    if (field.field_type === 'Long Input') {
                      return (
                        <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                          {field.placeholder}{' '}
                          <span
                            className={`value long-input border  border-1  rounded-3 text-break ${
                              fieldValue ? 'border-info' : 'border-secondary'
                            }`}
                          >
                            {fieldValue ? fieldValue : 'Not provided'}
                          </span>
                        </li>
                      );
                    }
                    if (field.name === 'mcm_name') {
                      return (
                        <div key={key}>
                          <li
                            key={key}
                            className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}
                          >
                            Individual Review Proccess{' '}
                            <span
                              className={`value border  border-1  rounded-3 text-break ${
                                fieldValue ? 'border-info' : 'border-secondary'
                              }`}
                            >
                              {mcm3RevProcessID ? mcm3RevProcessID : 'Not provided'}
                            </span>
                          </li>
                          <li
                            key={key + 1}
                            className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}
                          >
                            Reviewer name{' '}
                            <span
                              className={`value border  border-1  rounded-3 text-break ${
                                fieldValue ? 'border-info' : 'border-secondary'
                              }`}
                            >
                              {fieldValue ? fieldValue : 'Not provided'}
                            </span>
                          </li>
                        </div>
                      );
                    }
                    return (
                      <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                        {field.title}{' '}
                        <span
                          className={`value border  border-1  rounded-3 text-break ${
                            fieldValue ? 'border-info' : 'border-secondary'
                          }`}
                        >
                          {fieldValue ? fieldValue : 'Not provided'}
                        </span>
                      </li>
                    );
                  })}
                  {secondFormVars?.map((field, key) => {
                    //dpds disabled by client request
                    if (
                      field.name === 'has_completed_pdps' ||
                      field.name === 'has_completed_pdps_comments'
                    ) {
                      return;
                    }
                    const fieldValueArray = formFieldEntriesMCM3.filter(
                      userField => userField[0] === field.name,
                    );
                    let fieldValue;

                    for (const [key, value] of Object.entries(fieldValueArray)) {
                      fieldValue = value[1];
                    }
                    if (field.field_type === 'Long Input') {
                      return (
                        <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                          {field.placeholder}{' '}
                          <span
                            className={`value long-input border  border-1  rounded-3 text-break ${
                              fieldValue ? 'border-info' : 'border-secondary'
                            }`}
                          >
                            {fieldValue ? fieldValue : 'Not provided'}
                          </span>
                        </li>
                      );
                    }
                    return (
                      <li key={key} className={`${fieldValue ? '' : 'text-black-50  fs-6 text'}`}>
                        {field.title}{' '}
                        <span
                          className={`value border  border-1  rounded-3 text-break ${
                            fieldValue ? 'border-info' : 'border-secondary'
                          }`}
                        >
                          {fieldValue ? fieldValue : 'Not provided'}
                        </span>
                      </li>
                    );
                  })}
                </div>
              </div>
              <div className="border shadow border-1  rounded-3 ">
                <h5 className="pt-5 px-5">
                  <label htmlFor="has_completed_pdps">
                    The applicant has completed accredited PDP? (Yes / No)
                  </label>
                  <select
                    className="form-select form-select-sm my-3"
                    onChange={e => {
                      setHasCompletedPdps(e.target.value);
                    }}
                    name="has_completed_pdps"
                  >
                    <option value="">Please select</option>
                    <option selected={hasCompletedPdps?.includes('Yes')} value="Yes">
                      Yes
                    </option>
                    <option selected={hasCompletedPdps?.includes('No')} value="No">
                      No
                    </option>
                  </select>
                </h5>
                {hasCompletedPdps?.includes('Yes') && (
                  <h5 className="px-5">
                    <label className="w-100 mt-3" htmlFor="has_completed_pdps_comments">
                      Please indicate which or select from database of accredited PDPs:
                      https://enterprof.org/enter-registered-professional-development-programs
                    </label>
                    <textarea
                      value={hasCompletedPdpsComments}
                      onChange={e => {
                        setHasCompletedPdpsComments(e.target.value);
                      }}
                      className="w-100 mt-2"
                      name="has_completed_pdps_comments"
                      type="text"
                    />
                  </h5>
                )}
                <h5 className="px-5 pb-5">
                  <label className="w-100 mt-3" htmlFor="generalComments">
                    General Comments
                  </label>
                  <textarea
                    value={generalComments}
                    onChange={e => {
                      setGeneralComments(e.target.value);
                    }}
                    className="w-100 mt-2"
                    name="generalComments"
                    type="text"
                  />
                </h5>
              </div>
              <div className="d-flex flex-row mt-2 w-100 justify-content-center">
                {/* <div> */}
                <button
                  disabled={!isDisabled}
                  className="btn btn-outline-primary m-3"
                  onClick={handleSendEmailOnDecline}
                  type="button"
                >
                  Decline
                </button>
                <button
                  disabled={!isDisabled}
                  className="btn btn-outline-primary m-3"
                  onClick={handleOnApprove}
                  type="button"
                >
                  Approve
                </button>
              </div>
            </Container>
          )}
          {/* Deprecated by clients request*/}
          {finalAction.includes('Decline') && (
            <div
              style={{ minHeight: '300px' }}
              className="d-flex flex-column justify-content-between align-items-center"
            >
              <h3>You have Declined to Register the Applicant</h3>
              <p>
                Send Email with all details
                <button
                  className="btn btn-outline-success m-3"
                  onClick={handleSendEmailOnDecline}
                  type="button"
                >
                  Send
                </button>
              </p>
              <ToggleButtonReviewsResults />
              <button
                className="btn btn-outline-primary m-3"
                onClick={handleStepBack}
                type="button"
              >
                back
              </button>
            </div>
          )}
          {/* Deprecated by clients request*/}
          {finalAction.includes('Approve') && (
            <div
              style={{ minHeight: '300px' }}
              className="d-flex flex-column justify-content-between align-items-center"
            >
              <PersonalPortfolioCardForm
                userUnderReview={userUnderReview}
                applicantID={applicantID}
                formLabelsFromPrismic={formLabelsFromPrismic}
              />
              <br />
              <h3>You have Approved the Application</h3>
              <p>
                Send Email with all details
                <button
                  className="btn btn-outline-success m-3"
                  onClick={handleOnApprove}
                  type="button"
                >
                  Send
                </button>
              </p>
              <ToggleButtonReviewsResults />
              <button
                className="btn btn-outline-primary m-3"
                onClick={handleStepBack}
                type="button"
              >
                back
              </button>
            </div>
            // <div
            //   style={{ minHeight: '300px' }}
            //   className="d-flex flex-column justify-content-between align-items-center"
            // >
            //   <Card style={{ width: '100%' }} bg="light">
            //     <Card.Header> Registration Details Form</Card.Header>
            //     <Card.Body>
            //       {/* After Registering Form */}
            //       <Formik
            //         enableReinitialize
            //         initialValues={initialValues}
            //         isSubmitting={true}
            //         validationSchema={Yup.object().shape({
            //           reg_number: Yup.number(),
            //           reg_level: Yup.string(),
            //         })}
            //         onSubmit={handleAddUserData}
            //       >
            //         {(props) => (
            //           <PersonalDataForm
            //             submitButtonLabel={'SUBMIT'}
            //             className={'professional-portfolio'}
            //             firebaseAuth={firebaseAuth}
            //             mainFormVars={formLabelsFromPrismic}
            //             {...props}
            //           />
            //         )}
            //       </Formik>
            //     </Card.Body>
            //   </Card>
            //   <br />
            // <br />
            // <h3>You have Approved the Application</h3>
            // <p>
            //   Send Email with all details
            //   <button
            //     className="btn btn-outline-success m-3"
            //     onClick={handleSendEmailOnApprove}
            //     type="button"
            //   >
            //     Send
            //   </button>
            // </p>
            // <ToggleButtonReviewsResults />
            // <button
            //   className="btn btn-outline-primary m-3"
            //   onClick={handleStepBack}
            //   type="button"
            // >
            //   back
            // </button>
            // </div>
          )}
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query FinalStepApprovalProccess($id: String, $lang: String) {
    prismicFinalStepApprovalProccess(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        title {
          text
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
    prismicMcmReviewForm(lang: { eq: $lang }) {
      data {
        applicants_portfolio_review
        enter_register_review
        mcm_form_title {
          text
        }
        body {
          ... on PrismicMcmReviewFormDataBodyForm {
            items {
              description
              field_type
              form_type
              name
              placeholder
              required
              radio_options
              title
            }
          }
        }
      }
    }
    prismicProfessionalPortfolioCard {
      data {
        card_labels {
          accredited_pdp_completed_label
          area_of_expertise_label
          country_label
          email_label
          registration_date_label
          registration_level_label
          registration_number_label
          university_label
          valid_until_date_label
        }
        title {
          text
        }
        body {
          ... on PrismicProfessionalPortfolioCardDataBodyForm {
            id
            items {
              description
              field_type
              form_type
              name
              placeholder
              radio_options
              required
              title
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(FinalStepApprovalProccess, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
